<template>
	<button :type="type" :class="classes" :disabled="disabled || loading" @click="handleClick">
		<i v-if="iconLeft" class="ux-btn-icon-left" :class="iconLeft"/>
		<slot></slot>
		<i v-if="iconRight" class="ux-btn-icon-right" :class="iconLeft"/>
	</button>
</template>

<script>
import clickHandler from '../click-handler';

export default {
	mixins: [clickHandler],

	props: {
		type: { type: String, default: 'button' },
		circle: { type: Boolean, default: false },
		block: { type: Boolean, default: false },
		color: { type: String, default: 'primary' },
		disabled: { type: Boolean, default: false },
		size: { type: String },
		iconLeft: { type: String },
		iconRight: { type: String },
		loading: { type: Boolean, default: false },
	},

	computed: {
		classes()
		{
			var cls = [];
			var sizes = ['large'];

			if (this.circle)
				cls.push('ux-circle-btn');
			else
				cls.push('ux-btn');	

			cls.push(`ux-btn-${this.color}`);

			if (this.block)
				cls.push('is-block');
		
			if (this.size && sizes.includes(this.size))
				cls.push(`is-${this.size}`);

			return cls;
		},
	},
}
</script>