<template>
	<div :class="`ux-checkbox-list is-${this.dir}`">
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		dir: { type: String, default: 'vert' },
	}
}
</script>