<template>
	<div>
		<ux-form ref="form">
			<div class="title-1">Forgot Password</div>

			<template v-if="!success">
				<div class="body-1">Enter your email address below to have reset instructions sent to you</div>
				<ux-text-field
					autofocus
					label="Email Address"
					:rules="rules.email"
					v-model="email"
				/>
			</template>

			<template v-else>
				<div class="body-1">Thank you! You will receive reset instructions via email shortly</div>
			</template>

			<ux-btn-list>
				<ux-btn v-if="!success" type="submit" color="primary" :loading="loading">Submit</ux-btn>
				<div class="flex-spacer"></div>
				<ux-btn color="text" to="/login">Back</ux-btn>
			</ux-btn-list>
		</ux-form>
	</div>
</template>

<script>
import api from '../auth-api';

import alerts from 'common/alerts';

export default {
	data()
	{
		return {
			loading: false,
			error: false,
			success: false,
			email: '',

			rules: {
				email: [ v => !!v || 'Please enter your email address' ],
			}
		}
	},

	methods: {
		async submit()
		{
			this.loading = true;
			this.error = false;
			this.success = false;

			try
			{
				// request reset instructions
				var result = await api.post('/send_reset_email', { email: this.email });

				// if the request was not successful display an error
				if (!result.data.success)
				{
					//this.error = result.data.msg;
					await alerts.show(result.data.msg, 'Error', 'error');
				}
				// otherwise clear the form and show a success message
				else
				{
					//this.error = false;
					this.success = true;
				}
			}
			catch(err)
			{
				// display error returned from server
				var msg = err.response ? err.response.data.error : err.message;
				this.success = false;
				//this.error = msg;
				alerts.show(msg, 'Error', 'error');
			}
			finally
			{
				this.loading = false;
			}
		}
	}
}
</script>