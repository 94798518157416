<template>
	<div class="webstore-main">
		<div class="top-bar">
		</div>

		<div class="shell-header">
			<div class="container">
				<div class="header-content">
					<a href="/" class="header-logo">Joint Task Force</a>
				</div>
			</div>
		</div>

		<div class="container">
			<div class="row justify-center">
				<div class="col-sm-12 col-md-8">
					<router-view/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {

	data()
	{
		return {
		}
	}
}
</script>