
export default {
	computed: {
		keyEvents()
		{
			var events = ['keyup', 'keydown', 'keypress', 'blur', 'focus'];
			var handlers = {};

			for(var name of events)
				if (this.$listeners[name])
					handlers[name] = this.$listeners[name];

			return handlers;
		}
	}
}