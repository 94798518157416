<template>
	<div>
		<ux-field-list v-for="item in items" :key="item[itemKey]">
			<ux-field-list-row v-for="hdr in headers" :key="hdr.value" :label="hdr.name">
				<slot :name="`item.${hdr.value}`" :item="item">{{ item[hdr.value] }}</slot>
			</ux-field-list-row>
		</ux-field-list>
	</div>
</template>

<script>
export default {
	props: {
		headers: { type: Array, required: true },
		items: { type: Array, required: true },
		itemKey: { type: String, default: 'id' },
	}
}
</script>