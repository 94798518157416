<template>
	<div :class="`ux-btn-list is-${dir}`">
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		dir: { type: String, default: 'horz' },
	}
}
</script>