<template>
	<div class="ux-table-wrapper">	
		<table class="ux-table is-striped" :class="{ 'is-loading': loading }" cellpadding="0" cellspacing="0">

			<!-- DATA TABLE HEADER -->
			<thead v-if="!hideHeader && items.length > 0">
				<tr>
					<th v-if="selectOn != null" class="buttons-cell" style="width: 60px;">
						<ui-checkbox v-if="selectMultiple" v-model="selectAll" hide-label />
					</th>

					<th v-for="h in headers" :key="h.value" :class="h.class || ''" :style="h.style || ''">
						<a v-if="h.sortable" href="#" :class="`sortable-header ${getSortableClass(h)}`" @click.prevent="onSortChange(h)">{{ h.name }}</a>
						<template v-else>{{ h.name }}</template>
					</th>
				</tr>
			</thead>

			<tbody>
				<template v-if="items.length > 0">
					<tr v-for="item in items" :key="item[itemKey]" >
						<td v-if="selectOn != null" class="buttons-cell" style="width: 60px;">
							<ui-checkbox
								hide-label
								v-if="selectMultiple"
								v-model="selectedItems"
								:value="item[selectOn]" 
							/>

							<ui-icon 
								v-else 
								:icon="isSelected(item[selectOn]) ? 'far fa-check-circle' : 'far fa-circle'" 
								@click="selectItem(item[selectOn])" 
							/>
						</td>

						<slot name="row" v-bind:item="item"></slot>
					</tr>
				</template>
				
				<tr v-else>
					<td class="empty-msg" :colspan="colSpan">{{ emptyText }}</td>
				</tr>
			</tbody>
		</table>

		<div v-if="pageCount > 0 || $slots.actions" class="pt-5">
			<slot name="actions"></slot>
			
			<div class="flex-spacer"></div>

			<ux-pagination 
				v-if="pageCount > 0" 
				:page="pagination.page || 0"
				:page-count="pageCount" 
				@input="onPageChange"
			/>
		</div>
	</div>
</template>

<script>
const validDirs = ['asc', 'desc'];

//import Pagination from './DataTablePagination.vue';

export default {
	//components: { Pagination },

	props: {
		loading: { type: Boolean, default: false },
		items: { type: Array, default: () => ([]) },
		itemKey: { type: String, default: 'id' },
		headers: { type: Array, default: () => ([]) },
		hideHeader: { type: Boolean, default: false },
		emptyText: { type: String, default: 'List is empty' },
		pagination: { type: Object, default: () => ({}) },
		totalRows: { type: Number, default: 0 },
		selectOn: { type: String, default: null },
		selectMultiple: { type: Boolean, default: false },
		selected: { type: Array, default: () => ([]) },
		compact: { type: Boolean, default: false },
	},

	computed: {
		colSpan()
		{
			var len = this.headers.length;
			
			if (this.selectOn != null)
				len++;

			return len;
		},

		pageCount()
		{
			var perPage = this.pagination.per_page ? this.pagination.per_page : 0;

			if (this.totalRows > perPage)
				return Math.ceil(this.totalRows / this.pagination.per_page);
			else
				return 0;
		},

		selectAll:{
			get()
			{
				return this.selected.length == this.items.length;
			},

			set(value)
			{
				if (value)
					this.selectedItems = this.items.map(i => i[this.selectOn]);
				else
					this.selectedItems = [];
			}
		},

		selectedItems: {
			get()
			{
				return this.selected;
			},

			set(value)
			{
				this.$emit('update:selected', value);
			}
		}
	},

	methods: {

		updatePagination(pg)
		{
			this.$emit('update:pagination', { ...this.pagination, ...pg });
		},

		getSortableClass(h)
		{
			var col = this.pagination.sort_by || '';
			var dir = this.pagination.sort_desc ? 'desc' : 'asc';
			
			return col == h.value ? `sort-${dir}` : 'sort-none';
		},

		onSortChange(h)
		{
			var pg = {};

			if (h.value == this.pagination.sort_by)
				pg.sort_desc = !this.pagination.sort_desc;
			else
				pg.sort_by = h.value;

			this.updatePagination(pg);
		},

		onPageChange(p)
		{
			this.updatePagination({ page: p });
		},

		isSelected(id)
		{
			return this.selectedItems.indexOf(id) >= 0;
		},

		selectItem(id)
		{
			this.selectedItems = [id];
		},

		/*
		// set whether or not an item is selected
		setItemSelected(id, flag)
		{
			// see if an item is already selected
			var current = this.selectedItems.slice();
			var pos = current.indexOf(id);

			// if we are selecting multiple..
			if (this.selectMultiple)
			{
				// if flag is true then select this item if it isn't already
				if (flag && pos == -1)
					current.push(id);
				// otherwise if flag is false and the item is selected then deselect it
				else if (!flag && pos >= 0)
					current.splice(pos, 1);
			}
			// otherwise just set the selection
			else
			{
				// if flag is true then set the selection to the specified item
				if (flag)
					current = [id];
				// otherwise if flag is false and the id is the selected item then clear the selection
				else if (!flag && pos >= 0)
					current = [];
			}

			this.selectedItems = current;
		},
		*/
	}
}
</script>