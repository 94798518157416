export default {
	props: {
		to: { type: [String, Object], default: null },
		href: { type: String, default: null },
		open: { type: String, default: null },
	},

	methods: {
		isClickable()
		{
			return this.to != null || this.href != null || this.open != null || (this.$listeners && this.$listeners.click);
		},

		handleClick(event)
		{
			// do nothing if the button is loading
			if (this.loading)
			{
				event.stopPropagation();
				event.preventDefault();
				return false;
			}
			// navigate to the given url if 'href' is specified
			else if (this.href)
			{
				event.stopPropagation();
				event.preventDefault();
				window.location = this.href;
			}
			// open a new tab if 'open' is specified
			else if (this.open)
			{
				event.stopPropagation();
				event.preventDefault();
				window.open(this.open, '_blank');
			}
			// navigate to the route if 'to' is specified
			else if (this.to && this.$router)
			{
				this.$router.push(this.to)
				event.stopPropagation();
				event.preventDefault();
				return false;
			}
			// otherwise emit a click event
			else
			{
				//event.stopPropagation();
				//event.preventDefault();
				this.$emit('click', event);
				//return false;
			}
		}
	}
}