<template>
	<ux-form-field :validate-value="value" v-bind="formFieldAttrs">
		<ux-select
			:value="value"
			:items="items"
			:item-text="itemText"
			:item-value="itemValue"
			:show-empty="showEmpty"
			:empty-text="emptyText"
			:empty-value="emptyValue"
			@input="$emit('input', $event)"
		/>
	</ux-form-field>	
</template>

<script>
import formFieldProps from '../form-field';

export default {
	mixins: [formFieldProps],

	props: {
		value: { default: '' },
		items: { type: Array, required: true },
		itemText: { default: 'text' },
		itemValue: { default: 'value' },
		showEmpty: { type: Boolean, default: false },
		emptyText: { default: '' },
		emptyValue: { default: '' },
	},
}
</script>