export default {
	data()
	{
		return {
			hasFocus: false,
		}
	},

	props: {
		hideLabel: { type: Boolean, default: false },
		label: { type: String, default: '' },
		hint: { type: String, default: '' },
		disabled: { type: Boolean, default: false },
		compact: { type: Boolean, default: false },
		rules: { type: Array, default: () => ([]) },
		validateValue: { default: null },
		prependIcon: { type: String, default: null },
		appendIcon: { type: String, default: null },
		error: { type: String, default: null },
	},

	computed: {
		formFieldAttrs()
		{
			return {
				hint: this.hint,
				label: this.label,
				disabled: this.disabled,
				compact: this.compact,
				rules: this.rules,
				validateValue: this.validateValue,
				hideLabel: this.hideLabel,
				prependIcon: this.prependIcon,
				appendIcon: this.appendIcon,
				error: this.error,
			};
		}
	},

	methods: {
		setFocus(flag)
		{
			if (flag)
				this.$emit('focus');
			else
				this.$emit('blur');

			this.hasFocus = flag;
		}
	}
}