<template>
	<div class="ux-dropdown">
		<select class="ux-control is-dropdown" :value="value" @input="$emit('input', $event.target.value)">
			<option v-if="showEmpty" :value="emptyValue">{{ emptyText }}</option>
			<option v-for="item in items" :key="item[itemValue]" :value="item[itemValue]" :selected="item[itemValue] == value">{{ item[itemText] }}</option>
		</select>
	</div>
</template>

<script>
export default {
	props: {
		value: { default: '' },
		items: { type: Array, required: true },
		itemText: { default: 'text' },
		itemValue: { default: 'value' },
		showEmpty: { type: Boolean, default: false },
		emptyText: { default: '' },
		emptyValue: { default: '' },
		disabled: { type: Boolean, default: false },
	},
}
</script>