
import 'styles/webstore.scss';

import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// import shell component
import LoginMain from 'webstore/login/comp/LoginMain.vue';
import LoginForm from 'webstore/login/comp/LoginForm.vue';
import ForgotPassword from 'webstore/login/comp/ForgotPassword.vue';

// import main functionality
import 'webstore/ux';

// setup router
const router = new VueRouter({
	mode: 'history',
	routes: [
		{ 
			name: 'webstore-login-main',
			path: '/login',
			component: LoginForm,
		},
		{
			name: 'webstore-forgot-password',
			path: '/forgot-password',
			component: ForgotPassword,
		}
	]
});

// initialize main vue instance
const app = new Vue({
	el: '#app-root',
	router,

	render(h) {
		return h(LoginMain);
	}
});