<template>
	<div class="ux-field-list-row">
		<div class="row-label">
			<slot name="label">{{ label }}</slot>
		</div>
		<div class="row-value">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		label: { type: String },
	}
}
</script>