<template>
	<ux-form-field :validate-value="value" v-bind="formFieldAttrs">
		<div class="ux-control">
			<vue-date-picker 
				color="#ffc000"
				style="height: 40px"
				:format="format"
				:value="value"
				@input="$emit('input', $event)"
			/>
		</div>
	</ux-form-field>
</template>

<script>
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

import formFieldProps from '../form-field';

export default {
	components: { VueDatePicker },
	mixins: [formFieldProps],

	props: {
		format: { type: String, default: 'YYYY-MM-DD' },
		value: { default: '' },
	},
}
</script>