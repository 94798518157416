<template>
	<div>
		<div class="title-1">Customer Login</div>

		<ux-form ref="form" @submit.prevent="submitLogin">
			<ux-text-field 
				autofocus
				label="Username"
				:rules="rules.username"
				v-model="username"
			/>

			<ux-text-field 
				type="password"
				label="Password"
				:rules="rules.password"
				v-model="password"
			/>

			<ux-btn-list>
				<ux-btn type="submit" color="primary" :loading="loading">Submit</ux-btn>
				<div class="flex-spacer"/>
				<ux-btn type="button" color="text" :disabled="loading" to="/forgot-password">Forgot Password</ux-btn>
			</ux-btn-list>
		</ux-form>
	</div>
</template>

<script>
import api from '../auth-api';

import alerts from 'common/alerts';

export default {
	data()
	{
		return {
			loading: false,
			username: '',
			password: '',
			rules: {
				username: [ v => !!v || 'Please enter your username' ],
				password: [ v => !!v || 'Please enter your password' ],
			}
		}
	},

	methods: {
		async submitLogin()
		{
			if (!this.$refs.form.validate())
				return;

			this.loading = true;

			try
			{
				var result = await api.post('/authenticate', { 
					username: this.username,
					password: this.password,
				});

				if (result.data.success)
				{
					await alerts.show('Login success', 'Success', 'success');

					if (this.$route.query.redirect)
						document.location = this.$route.query.redirect;
					else
						document.location = '/dashboard';
				}
				else
				{
					await alerts.show('Invalid username or password', 'Login failed', 'error');
				}
			}
			finally
			{
				this.loading = false;
			}
		}
	}
}
</script>