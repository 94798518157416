import Vue from 'vue';

import UxForm from './comp/UxForm.vue';
import UxFormField from './comp/UxFormField.vue';
import UxTextField from './comp/UxTextField.vue';
import UxTextareaField from './comp/UxTextAreaField.vue';
import UxSelect from './comp/UxSelect.vue';
import UxSelectField from './comp/UxSelectField.vue';
import UxCheckbox from './comp/UxCheckbox.vue';
import UxCheckboxList from './comp/UxCheckboxList.vue';
import UxRadio from './comp/UxRadio.vue';
import UxRadioList from './comp/UxRadioList.vue';
import UxBtn from './comp/UxBtn.vue';
import UxBtnList from './comp/UxBtnList.vue';
import UxPagination from './comp/UxPagination.vue';
import UxDatePicker from './comp/UxDatePicker.vue';
import UxDataTable from './comp/UxDataTable.vue';
import UxFieldList from './comp/UxFieldList.vue';
import UxFieldListRow from './comp/UxFieldListRow.vue';
import UxFieldListIterator from './comp/UxFieldListIterator.vue';

const comps = {
	UxForm, UxFormField, UxTextField, UxTextareaField, UxSelect, UxSelectField,
	UxCheckbox, UxCheckboxList, UxRadio, UxRadioList, UxBtn, UxBtnList, UxPagination,
	UxDatePicker, UxDataTable, UxFieldList, UxFieldListRow, UxFieldListIterator,
}

for(var compName in comps)
	Vue.component(compName, comps[compName]);
