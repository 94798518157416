<template>
	<ul class="ux-pagination">
		<li><button class="page-nav-btn" aria-label="First page" :disabled="page == 0" @click="gotoPage(0)"><i class="fas fa-angle-double-left"/></button></li>
		<li><button class="page-nav-btn" aria-label="Previous page" :disabled="page == 0" @click="gotoPage(page-1)"><i class="fas fa-angle-left"/></button></li>
		<li class="current-page">Page {{ page + 1 }} of {{ pageCount }}</li>
		<li><button class="page-nav-btn" aria-label="Next page" :disabled="page+1 == pageCount" @click="gotoPage(page+1)"><i class="fas fa-angle-right"/></button></li>
		<li><button class="page-nav-btn" aria-label="Last page" :disabled="page+1 == pageCount" @click="gotoPage(pageCount-1)"><i class="fas fa-angle-double-right"/></button></li>
	</ul>	
</template>

<script>
export default {
	props: {
		pageCount: { type: Number, required: true },
		page: { type: Number, required: true },
	},

	model: {
		prop: 'page',
		event: 'input',
	},

	methods: {
		gotoPage(n)
		{
			this.$emit('input', n);
		}
	}
}
</script>