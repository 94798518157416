<template>
	<ux-form-field :validate-value="value" v-bind="formFieldAttrs">
		<textarea 
			class="ux-control"
			:rows="rows"
			:type="type" 
			:readonly="readonly"
			:placeholder="placeholder"
			:autofocus="autofocus"
			:value="value"
			v-on="keyEvents"
			@input="$emit('input', $event.target.value)"
		/>
	</ux-form-field>	
</template>

<script>
import formFieldProps from '../form-field';
import textField from '../text-field';

export default {
	mixins: [formFieldProps, textField],

	props: {
		type: { type: String, default: 'text' },
		value: { type: String, default: '' },
		autofocus: { type: Boolean, default: false },
		placeholder: { type: String, default: '' },
		readonly: { type: Boolean, default: false },
		rows: { type: [Number, String], default: 3 }
	},
}
</script>